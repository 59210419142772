import {aweApplication} from "../awe";
import {DefaultSpin} from "../data/options";
import {getIconTemplate} from "./component";

export const templateButtonCheckbox = `<label ng-show="controller.visible" class="criterion btn btn-awe validator input {{criterionClass}} focus-target" ng-class="{'active btn-primary': checked, 'disabled': controller.readonly}" ui-dependency="dependencies" ng-attr-criterion-id="{{::controller.id}}" ng-cloak>
  <awe-context-menu ng-cloak></awe-context-menu>
  <input type="checkbox" class="form-control px {{classes}}" ng-attr-id="{{::controller.id}}" ng-attr-name="{{::controller.id}}" 
         ng-model="checked" ng-change="updateSelected(checked)" ng-disabled="controller.readonly" 
         ng-focus="focus()" ng-blur="blur()"/>
  <i></i>       
  ${getIconTemplate("{{::iconClass}}", "i")}
  <span class="lbl label-{{::size}}" title="{{controller.title| translateMultiple}}" >
    <i ng-if="::controller.help" class="help-target fa fa-fw fa-question-circle"></i>
    {{controller.label| translateMultiple}}
  </span>
  <awe-loader class="loader" ng-if="controller.loading" icon-loader="{{::iconLoader}}" ng-cloak></awe-loader>
</label>`;
export const templateButtonRadio = `<label ng-show="controller.visible" class="criterion btn btn-awe validator input {{criterionClass}} focus-target" ng-class="{'active btn-primary': component.model.selected === component.value, 'disabled': controller.readonly}" ui-dependency="dependencies" ng-attr-criterion-id="{{::controller.id}}" ng-cloak>
  <awe-context-menu ng-cloak></awe-context-menu>
  <input type="radio" class="form-control px {{classes}}" ng-attr-id="{{::controller.id}}" ng-attr-name="{{::controller.id}}" ng-focus="focus()" ng-blur="blur()"
         ng-model="component.model.selected" ng-value="component.value" ng-change="component.modelChange()" ng-disabled="controller.readonly"/>
  <i></i>       
  ${getIconTemplate("{{::iconClass}}", "i")}
  <span class="lbl label-{{::size}}" title="{{controller.title| translateMultiple}}" >
    <i ng-if="::controller.help" class="help-target fa fa-fw fa-question-circle"></i>
    {{controller.label| translateMultiple}}
  </span>
  <awe-loader class="loader" ng-if="controller.loading" icon-loader="{{::iconLoader}}" ng-cloak></awe-loader>
</label>`;

export const templateInputCheckbox = `<div ng-show="controller.visible" class="criterion {{criterionClass}}" ui-dependency="dependencies" ng-attr-criterion-id="{{::controller.id}}" ng-cloak>
  <awe-context-menu ng-cloak></awe-context-menu>
  <div ng-class="::groupClass" ng-cloak>
    <label ng-attr-for="{{::controller.id}}" class="control-label label-{{::size}}" ng-cloak></label>
    <div class="validator input">
      <label class="checkbox" title="{{controller.title | translateMultiple}}" >
        <input type="checkbox" class="form-control px {{classes}}" ng-attr-id="{{::controller.id}}" ng-attr-name="{{::controller.id}}"
               ng-model="checked" ng-change="updateSelected(checked)" ng-disabled="controller.readonly"/>
        <span class="lbl label-{{::size}}">
          <i ng-if="::controller.help" class="help-target fa fa-fw fa-question-circle"></i>
          {{controller.label| translateMultiple}}
        </span>
        <awe-loader class="loader" ng-if="controller.loading" icon-loader="{{::iconLoader}}" ng-cloak></awe-loader>
      </label>
    </div>
  </div>
</div>`;

export const templateColumnCheckbox = `<div ng-show="component.controller.visible" class="validator column-input criterion column-checkbox no-animate" ui-dependency="dependencies" ng-click="click($event)" ng-cloak>
  <div class="input">
    <label class="checkbox" title="{{component.model.values[0].title | translateMultiple}}" >
      <input type="checkbox" class="px form-control" ng-model="checked" ng-change="updateSelected(checked)" ng-disabled="component.controller.readonly || !component.editing"/>
      <span class="lbl"></span>
    </label>
  </div>
  <awe-loader class="loader no-animate" ng-if="component.controller.loading" icon-loader="{{::iconLoader}}" ng-cloak></awe-loader>
</div>`;

export const templateRadio = `<div ng-show="controller.visible" class="criterion {{criterionClass}}" ui-dependency="dependencies" ng-attr-criterion-id="{{::controller.id}}" ng-cloak>
  <awe-context-menu ng-cloak></awe-context-menu>
  <div ng-class="::groupClass" ng-cloak>
    <label ng-attr-for="{{::controller.id}}" ng-class="::labelClass" ng-cloak></label>
    <div class="validator input">
      <label class="radio" title="{{controller.title| translateMultiple}}" >
        <input type="radio" class="form-control px {{classes}}" ng-attr-id="{{::controller.id}}" ng-attr-name="{{::controller.group}}"
               ng-model="component.model.selected" ng-value="component.value" ng-change="component.modelChange()" ng-disabled="controller.readonly"/>
        <span class="lbl label-{{::size}}">
          <i ng-if="::controller.help" class="help-target fa fa-fw fa-question-circle"></i>
          {{controller.label| translateMultiple}}
        </span>
        <awe-loader class="loader" ng-if="controller.loading" icon-loader="{{::iconLoader}}" ng-cloak></awe-loader>
      </label>
    </div>
  </div>
</div>`;

// Checkbox Radio service
aweApplication.factory('CheckboxRadio',
  ['Criterion', 'Control', 'AweUtilities',
    /**
     * Criterion generic methods
     * @param {Criterion} Criterion
     * @param {Control} Control
     * @param {Utilities} Utilities
     */
    function (Criterion, Control, Utilities) {

      /**
       * Button constructor
       * @param {Scope} scope Button scope
       * @param {String} id Button id
       * @param {String} element Button element
       */
      function CheckboxRadio(scope, id, element) {
        this.scope = scope;
        this.id = id;
        this.element = element;
        this.component = new Criterion(this.scope, this.id, this.element);

        // Link to initialization methods
        let  checkboxradio = this;
        this.component.asCheckbox = function () {
          return checkboxradio.asCheckbox();
        };
        this.component.asRadio = function () {
          return checkboxradio.asRadio();
        };
        return this.component;
      }

      CheckboxRadio.prototype = {
        /**
         * Initialize as checkbox
         */
        asCheckbox: function () {
          // Initialize criterion
          let  component = this.component;
          if (!component.asCriterion()) {
            // If criterion is wrong, cancel initialization
            return false;
          }

          // Define spin options
          component.scope.spinOptions = component.scope.spinOptions || DefaultSpin.small;

          // Add a default value if values has no data
          let  checkedValue = component.model.defaultValues || 1;

          // Flag to checked or not
          component.scope.checked = String(component.model.selected) === String(checkedValue);
          component.model.selected = component.scope.checked ? checkedValue : 0;

          // Add group to address
          component.address.group = component.controller.group;

          /******************************************************************************
           * COMPONENT METHODS
           *****************************************************************************/

          /**
           * Restore criterion
           */
          component.onRestore = function () {
            component.scope.updateSelected(String(component.model.defaultValues) === String(checkedValue));
          };

          /**
           * Reset criterion
           */
          component.onReset = function () {
            component.scope.updateSelected(false);
          };

          /******************************************************************************
           * SCOPE METHODS
           *****************************************************************************/

          /**
           * Update checkbox selected value
           * @param {boolean} checked Checkbox is checked
           */
          component.scope.updateSelected = function (checked) {
            component.model.selected = checked ? checkedValue : 0;
            component.model.values = [{value:component.model.selected, label:component.model.selected}];
            component.modelChange();
          };

          /**********************************************************************/
          /* API METHODS                                                        */
          /**********************************************************************/

          /**
           * API link to update the model values
           * @param {type} data
           */
          component.api.updateModelValues = function (data) {
            let  model = component.model;
            if (model && "selected" in data) {
              // Store selected values
              model.selected = Control.formatDataList(data.selected);
              component.scope.checked = String(model.selected) === String(checkedValue);
            }
          };

          // Return initialization
          return true;
        },
        /**
         * Initialize as radio
         */
        asRadio: function () {
          // Initialize criterion
          let  component = this.component;

          // Define spin options
          component.scope.spinOptions = component.scope.spinOptions || DefaultSpin.small;

          // Initialize component as criterion
          if (!component.asCriterion()) {
            // If criterion is wrong, cancel initialization
            return false;
          }

          // Generate group address
          let  group = component.controller.group;
          let  groupAddress = _.cloneDeep(component.address);
          groupAddress.component = group;

          // Set model value
          component.value = component.model.values[0].value;

          // Change model with group attribute
          let  viewModels = Control.getAddressViewModel(component.address);
          if (!(group in viewModels)) {
            viewModels[group] = {
              selected: null,
              defaultValues: component.model.selected
            };
          }

          // Change group selected model
          let  selectedList = Utilities.asArray(component.model.selected);
          if (selectedList.length > 0) {
            viewModels[group].selected = selectedList[0];
            viewModels[group].defaultValues = selectedList[0];
          }

          // Change model to group model
          component.model = viewModels[group];

          /******************************************************************************
           * COMPONENT METHODS
           *****************************************************************************/

          /**
           * On model change
           */
          component.modelChange = function () {
            component.model.selected = component.model.selected !== '' ? component.model.selected : null;
            Control.publishModelChanged(groupAddress, {selected: component.model.selected});
          };

          /**
           * Restore criterion
           */
          component.onRestore = function () {
            Control.restoreModelAttribute(groupAddress, "selected");
          };

          /**********************************************************************/
          /* API METHODS                                                        */
          /**********************************************************************/
          // Update API for single radio
          let  retrieveEmptyObject = function () {
            return {};
          };
          component.api.getData = retrieveEmptyObject;
          component.api.getPrintData = retrieveEmptyObject;

          /**********************************************************************/
          /* GROUP API METHODS                                                  */
          /**********************************************************************/

          let  viewApis = Control.getAddressViewApi(component.address);
          viewApis[group] = {};

          // Add API for group
          /**
           * Basic getData function
           * @returns {Object} Data from criteria
           */
          viewApis[group].getData = function () {
            // Initialize data
            let  data = {};
            data[group] = component.model.selected;
            return data;
          };

          /**
           * Printable data function (To be overwritten on complex directives)
           * @returns {Object} Data from criteria
           */
          viewApis[group].getPrintData = function () {
            // Initialize data
            return viewApis[group].getData();
          };

          /**
           * Basic getAttribute function (To be overwritten on complex directives)
           * @param {type} attribute Attribute to check
           * @returns {undefined}
           */
          viewApis[group].getAttribute = function (attribute) {
            return component.getAttribute(attribute);
          };

          /**
           * API link to update the selected values
           * @param {type} data
           */
          viewApis[group].updateModelValues = function (data) {
            if (component.model && "selected" in data) {
              // Store selected values
              component.model.selected = Control.formatDataList(data.selected);
            }
          };

          // Return initialization
          return true;
        }
      };
      return CheckboxRadio;
    }
  ]);