import { aweApplication } from "./../../awe";

// Sparkline service
aweApplication.factory('SparkLine',
    ['ServerData',
      /**
       * Sparkline generic methods
       * @param {service} serverData Server data access methods
       */
      function (serverData) {

      }
    ]);